<template>
  <div>
    <v-container
      fluid
      class="bg-content pa-0"
      v-if="!switchEdit"
    >
      <v-row>
        <v-col
          cols="12"
          md="7"
        >
          <div>
            <div class="card-content">
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.name }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ warehouseName }}</p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.email }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ warehouseEmail }}</p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.phone }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">
                  {{ warehousePhoneNumber }}
                </p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.repsonsable }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ reponsableWarehouse }}</p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.address }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ wahrehouseAddress }}</p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{
                    selectLanguage == "sp"
                      ? "Dirección específica"
                      : "Specific address"
                  }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">
                  {{ wahrehouseAddressOptional }}
                </p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.city }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ warehouseCity }}</p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.zipCode }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ warehousePostalCode }}</p>
              </div>
              <div class="display-flex align-items-center border-bottom pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.country }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">
                  {{ warehouseCountryName }}
                </p>
              </div>
              <div class="display-flex align-items-center pa-2">
                <p class="text-header mon-medium">
                  {{ texts.warehouse.tab1.list.state }}
                </p>
                <v-spacer></v-spacer>
                <p class="text-result mon-regular">{{ warehouseStateName }}</p>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
        >
          <div>
            <GmapMap
              :center="center"
              :zoom="4"
              :options="mapOptions"
              style="width: 100%; height: 350px"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center = m.position"
              />
            </GmapMap>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="separator-line" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            v-if="isResponsive"
            class="mb-9"
          >
            <v-btn
              @click="switchEdit = true"
              class="button-add-warehouse mon-bold"
              elevation="0"
              style="width: 100%;"
            >
              {{ texts.warehouse.buttons.modify }}
            </v-btn>
            <v-btn
              @click="
                $router.push({ name: 'Warehouse' });
                $store.commit('setTabWarehouse', 0);
              "
              class="button-close mon-regular mt-5"
              elevation="0"
              style="width: 100%;"
            >
              {{ texts.warehouse.buttons.return }}
            </v-btn>
            <v-btn
              class="button-tertiary mon-bold mt-5"
              elevation="0"
              style="width: 100%;"
              @click="setDialogDelete"
            >
              {{ texts.warehouse.buttons.delete }}
            </v-btn>
          </div>
          <div
            v-else
            class="display-flex align-items-center mb-9"
          >
            <v-btn
              class="button-tertiary mon-bold"
              elevation="0"
              @click="setDialogDelete"
            >
              {{ texts.warehouse.buttons.delete }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="
                $router.push({ name: 'Warehouse' });
                $store.commit('setTabWarehouse', 0);
              "
              class="button-close mon-regular"
              elevation="0"
            >
              {{ texts.warehouse.buttons.return }}
            </v-btn>
            <v-btn
              @click="switchEdit = true"
              class="button-add-warehouse mon-bold ml-4"
              elevation="0"
            >
              {{ texts.warehouse.buttons.modify }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else
      fluid
      class="bg-content pa-0"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <v-text-field
                    v-model="warehouseName"
                    type="text"
                    :label="texts.warehouse.addWarehouse.warehouseName.label"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehouseName.placeholder
                    "
                    persistent-placeholder
                    maxLength="60"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <div>
                  <v-text-field
                    v-model="warehousePhoneNumber"
                    type="text"
                    :label="
                      texts.warehouse.addWarehouse.warehousePhoneNumber.label
                    "
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehousePhoneNumber
                        .placeholder
                    "
                    persistent-placeholder
                    @keypress="soloNumbers"
                    maxLength="13"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div>
                  <v-text-field
                    v-model="warehouseEmail"
                    type="text"
                    :label="texts.warehouse.addWarehouse.warehouseEmail.label"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehouseEmail.placeholder
                    "
                    persistent-placeholder
                    maxLength="85"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <v-text-field
                    v-model="reponsableWarehouse"
                    type="text"
                    :label="
                      texts.warehouse.addWarehouse.reponsableWarehouse.label
                    "
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.reponsableWarehouse
                        .placeholder
                    "
                    persistent-placeholder
                    maxLength="40"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div class="content-input-map">
                  <p class="content-input-map-label mon-regular">
                    {{ texts.warehouse.addWarehouse.wahrehouseAddress.label }}
                  </p>
                  <GmapAutocomplete
                    @place_changed="setPlace"
                    autocomplete=""
                    :value="wahrehouseAddress"
                    :options="autocompleteOptions"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <v-text-field
                    v-model="wahrehouseAddressOptional"
                    type="text"
                    :label="
                      selectLanguage == 'sp'
                        ? 'Dirección específica'
                        : 'Specific address'
                    "
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      selectLanguage == 'sp'
                        ? 'Apt, suite, edificio, piso'
                        : 'Apt, suite, building, floor'
                    "
                    persistent-placeholder
                    maxLength="60"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <div>
                  <v-select
                    v-model="warehouseCountry"
                    :items="itemsCountries"
                    item-text="sName"
                    item-value="sCountryId"
                    :label="texts.warehouse.addWarehouse.warehouseCountry.label"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehouseCountry.placeholder
                    "
                    @input="selecState"
                  >
                  </v-select>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div>
                  <v-select
                    v-model="warehouseState"
                    :items="itemsStates"
                    item-text="sName"
                    item-value="sStateId"
                    :label="texts.warehouse.addWarehouse.warehouseState.label"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehouseState.placeholder
                    "
                    @input="selecState"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <div>
                  <v-text-field
                    v-model="warehouseCity"
                    type="text"
                    :label="texts.warehouse.addWarehouse.warehouseCity.label"
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehouseCity.placeholder
                    "
                    persistent-placeholder
                    maxLength="60"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <div>
                  <v-text-field
                    v-model="warehousePostalCode"
                    type="text"
                    :label="
                      texts.warehouse.addWarehouse.warehousePostalCode.label
                    "
                    outlined
                    color="#000000"
                    dense
                    class="global-inputs mon-regular"
                    :placeholder="
                      texts.warehouse.addWarehouse.warehousePostalCode
                        .placeholder
                    "
                    persistent-placeholder
                    maxLength="5"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <div>
            <GmapMap
              :center="center"
              :zoom="4"
              :options="mapOptions"
              style="width: 100%; height: 380px"
            >
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center = m.position"
              />
            </GmapMap>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="separator-line" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div
            v-if="isResponsive"
            class="mb-9"
          >
            <v-btn
              @click="editWarehouse"
              :disabled="!validateForm"
              :loading="bLoading"
              class="button-add-warehouse mon-bold"
              elevation="0"
              style="width: 100%;"
            >
              {{ texts.warehouse.buttons.save }}
            </v-btn>
            <v-btn
              @click="switchEdit = false"
              class="button-close mon-regular mt-5"
              elevation="0"
              style="width: 100%;"
            >
              {{ texts.warehouse.buttons.cancel }}
            </v-btn>
          </div>
          <div
            v-else
            class="display-flex align-items-center mb-9"
          >
            <v-btn
              @click="switchEdit = false"
              class="button-close mon-regular"
              elevation="0"
            >
              {{ texts.warehouse.buttons.cancel }}
            </v-btn>
            <v-spacer />
            <v-btn
              @click="editWarehouse"
              :disabled="!validateForm"
              :loading="bLoading"
              class="button-add-warehouse mon-bold ml-4"
              elevation="0"
            >
              {{ texts.warehouse.buttons.save }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "EditWarehouseTabContent1Layout",
  props: {
    texts: {
      type: Object,
      default: "",
    },
    arr: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      //VARIABLES
      itemsCountries: [],
      itemsStates: [],
      warehouseName: "",
      wahrehouseAddress: "",
      wahrehouseAddressOptional: "",
      warehouseCountry: "",
      warehouseState: "",
      warehouseCity: "",
      warehousePostalCode: "",
      warehousePhoneNumber: "",
      warehouseEmail: "",
      reponsableWarehouse: "",
      warehouseLatitude: "",
      warehouseLongitude: "",
      warehouseCountryName: "",
      warehouseStateName: "",
      bLoading: false,
      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      oPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
      },
      autocompleteOptions: {
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },
      disabledData: true,

      //switch edit
      switchEdit: false,
      isResponsive: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    setDialogDelete: function () {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.warehouse.textTitleDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}`,
        redirect: "/warehouse",
      });
    },
    setPlace: function (place) {
      this.warehouseCity = "";
      this.warehousePostalCode = "";

      // var dom = document.createElement("div");
      // dom.innerHTML = place.adr_address;
      // let postal_code_html = dom.getElementsByClassName("postal-code"),
      //   locality_html = dom.getElementsByClassName("locality");
      // this.warehousePostalCode = postal_code_html[0].innerHTML;
      // this.warehouseCity = locality_html[0].innerHTML;

      let split_name = place.formatted_address.split(",");
      let name_address = split_name[0] + "," + split_name[1];

      this.wahrehouseAddress = name_address;
      this.currentPlace = place;
      this.oPlace = place;

      this.addMarker();
      this.exampleFill(place);
    },
    exampleFill: function (place) {
      if (place.address_components) {
        const addressComponents = place.address_components;
        let selectedCountry;
        const countryAddressComponent = addressComponents.find((adc) =>
          adc.types.includes("country")
        );

        if (countryAddressComponent.short_name === "MX") {
          this.warehouseCountry = "México";
          selectedCountry = this.itemsCountries.find(
            (c) => c.sName === "México" || c.sName === "Mexico"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
        if (countryAddressComponent.short_name === "US") {
          selectedCountry = this.itemsCountries.find(
            (c) =>
              c.sName === "Estados Unidos de América" ||
              c.sName === "United States of America"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
      }
    },
    addMarker: function () {
      this.markers = [];

      if (this.currentPlace) {
        this.warehouseLatitude = this.currentPlace.geometry.location.lat();
        this.warehouseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      }
    },
    editWarehouse: function () {
      this.bLoading = true;

      const payload = {
        oWarehouse: {
          sName: this.warehouseName,
          sPhoneNumber: this.warehousePhoneNumber,
          sEmail: this.warehouseEmail,
          sContactName: this.reponsableWarehouse,
        },
        oLocation: {
          sLocationAddress: this.wahrehouseAddress,
          sLocationOptionalAddress: this.wahrehouseAddressOptional,
          sLocationCity: this.warehouseCity,
          sLocationStateId: this.warehouseState,
          sLocationZipCode: this.warehousePostalCode,
          dLocationLatitude: this.warehouseLatitude,
          dLocationLongitude: this.warehouseLongitude,
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/customers/enterprises/${this.$store.state.sEnterpriseId}/warehouses/${this.arr.sCustomerWarehouseId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.switchEdit = false;

          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.bLoading = false;
          this.switchEdit = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
    clear: function () {
      this.warehouseName = "";
      this.wahrehouseAddress = "";
      this.wahrehouseAddressOptional = "";
      this.warehouseCountry = "";
      this.warehouseState = "";
      this.warehouseCity = "";
      this.warehousePostalCode = "";
      this.warehousePhoneNumber = "";
      this.warehouseEmail = "";
      this.reponsableWarehouse = "";
      this.warehouseLatitude = "";
      this.warehouseLongitude = "";
    },
    getCountries: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`)
        .then((response) => {
          this.itemsCountries = response.data.results;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function () {
      this.state = "";
      this.getStates();
    },
    getStates: function () {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.warehouseCountry}/states`
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          if (this.oPlace) {
            const addressComponents = this.oPlace.address_components;
            for (let i = 0; i < addressComponents.length; i++) {
              //administrative_area_level_1 es el type que usa google para los estados
              if (
                addressComponents[i].types.includes(
                  "administrative_area_level_1"
                )
              ) {
                let selectedState = this.itemsStates.find(
                  (s) =>
                    this.normalizeString(s.sName) ===
                    this.normalizeString(addressComponents[i].long_name)
                );
                this.warehouseState = selectedState.sStateId;
              }

              if (addressComponents[i].types.includes("postal_code")) {
                //codigo postal
                this.warehousePostalCode = addressComponents[i].long_name;
              }

              //administrative_area_level_1 es el type que usa google para la ciudad
              if (addressComponents[i].types.includes("locality")) {
                //ciudad
                this.warehouseCity = addressComponents[i].long_name;
              }
            }
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    normalizeString: function (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    soloNumbers: function (evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return (
        this.warehouseName !== "" &&
        this.wahrehouseAddress !== "" &&
        this.wahrehouseAddress !== null &&
        this.warehouseCountry !== "" &&
        this.warehouseState !== "" &&
        this.warehouseCity !== "" &&
        this.warehousePostalCode !== "" &&
        this.warehousePhoneNumber !== "" &&
        this.warehouseEmail !== "" &&
        this.reponsableWarehouse !== "" &&
        this.warehouseLatitude !== "" &&
        this.warehouseLongitude !== ""
      );
    },
  },
  watch: {
    arr: function () {
      if (this.arr) {
        this.warehouseName = this.arr.oWarehouse.sName;
        this.warehousePhoneNumber = this.arr.oWarehouse.sPhoneNumber;
        this.warehouseEmail = this.arr.oWarehouse.sEmail;
        this.reponsableWarehouse = this.arr.oWarehouse.sContactName;
        this.wahrehouseAddress = this.arr.oLocation.sAddress;
        this.wahrehouseAddressOptional =
          this.arr.oLocation.sLocationOptionalAddress;
        this.warehousePostalCode = this.arr.oLocation.sZipCode;
        this.warehouseCity = this.arr.oLocation.sCity;
        this.warehouseCountry = this.arr.oLocation.sCountryId;
        this.warehouseState = this.arr.oLocation.sStateId;
        this.warehouseLatitude = this.arr.oLocation.dLatitude;
        this.warehouseLongitude = this.arr.oLocation.dLongitude;
        this.warehouseCountryName = this.arr.oLocation.sCountryName;
        this.warehouseStateName = this.arr.oLocation.sStateName;

        const marker = {
          lat: this.arr.oLocation.dLatitude,
          lng: this.arr.oLocation.dLongitude,
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
        this.getCountries();
      }
    },
  },
};
</script>

<style>
.bg-content .global-inputs input:disabled,
.bg-content .content-input-map input:disabled {
  pointer-events: auto !important;
  cursor: no-drop !important;
  color: #00000029 !important;
}
</style>

<style scoped>
.text-header {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-result {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.button-delete {
  height: 40px !important;
  background: #d8686a 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px !important;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: initial;
}

.content-input-map-label {
  font-size: 10px;
  position: absolute;
  top: -8px;
  left: 12px;
  color: #00000090;
}

.content-input-map {
  position: relative;
}

.content-input-map input {
  width: 100%;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 9px !important;
  font-size: 14px;
  font-family: "monserrat-regular", sans-serif !important;
  padding: 0px 15px;
  letter-spacing: 0px;
  color: #000000;
}

.content-input-map input:focus-visible {
  outline: none;
}

.content-input-map input::-webkit-input-placeholder {
  color: #fff;
}

.content-input-map input:-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.content-input-map input:-ms-input-placeholder {
  color: #fff;
}

.content-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-close {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  text-transform: initial;
}

.button-add-warehouse {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  text-transform: initial;
}

.separator-line,
.border-bottom {
  border-bottom: 1px solid #cecece;
}

.bg-content {
  margin-top: 10px;
}
</style>